import React from 'react';
import { Resource } from 'react-admin';
import { residentResource } from './residents';
import { casesResource } from './cases';
import { authsResource } from './auths';
import { ancillaryAuthsResource } from './ancillary_auths';
import { denialsResource } from './denials';
import { updatesResource } from './updates';
import { censusFileResource } from '../pages/census/census';
import { censusEventResource } from '../pages/census/censusPage';
import { pharmLogResource } from './pharmLog';
import { facilityResource } from '../pages/facility/facility';
import { contractsResource } from './payers/contracts-list';
import { reportsResource } from './reports-old';
import { tasksResource } from './tasks/tasks_list';
import { facilityFaxLogsResource } from './facility-fax-logs';
import { faxInboxResource } from './fax-inbox';
import { globalPayersResource } from '../pages/global-payers';
import { connectionsResource } from '../pages/connections';
import { servicesResource } from '../pages/services';
import { categoriesResource } from '../pages/categories';
import { taskBuilderResource } from './tasks/task-builder';
export * from './residents';
export * from './auths';
export * from './ancillary_auths';
export * from './denials';
export * from './updates';
//export * from './progress';
export * from './pharmLog';
export * from './tasks/tasks_list';
export * from './tasks/task-builder';

export const sidebarResources = [
  {
    key: residentResource.name,
    name: residentResource.name,
    Icon: residentResource.icon,
    label: 'Residents',
  },
  // {
  //   key: casesResource.name,
  //   name: casesResource.name,
  //   Icon: casesResource.icon,
  // },
  {
    key: censusFileResource.name,
    name: 'census',
    Icon: censusFileResource.icon,
    label: 'Census',
  },
  {
    key: 'divider-1',
    name: 'divider',
  },
  {
    key: authsResource.name,
    name: authsResource.name,
    Icon: authsResource.icon,
  },
  {
    key: denialsResource.name,
    name: denialsResource.name,
    Icon: denialsResource.icon,
  },
  {
    key: tasksResource.name,
    name: 'tasks/not-completed',
    activeName: 'tasks',
    Icon: tasksResource.icon,
    label: 'Tasks',
  },
  {
    key: updatesResource.name,
    name: updatesResource.name,
    Icon: updatesResource.icon,
  },
  {
    key: ancillaryAuthsResource.name,
    name: ancillaryAuthsResource.name,
    Icon: ancillaryAuthsResource.icon,
  },
  {
    key: pharmLogResource.name,
    name: pharmLogResource.name,
    Icon: pharmLogResource.icon,
    label: 'Pharm log',
  },
  {
    key: 'divider-2',
    name: 'divider',
  },
  {
    key: facilityFaxLogsResource.name,
    name: facilityFaxLogsResource.name,
    Icon: facilityFaxLogsResource.icon,
    label: 'Fax/email log',
  },
  {
    key: faxInboxResource.name,
    name: faxInboxResource.name,
    Icon: faxInboxResource.icon,
    label: 'Fax Inbox',
  },
  {
    key: 'facility-details',
    name: 'facility-details',
    Icon: facilityResource.icon,
    label: 'Facility details',
  },
  {
    key: contractsResource.name,
    name: contractsResource.name,
    Icon: contractsResource.icon,
    label: 'Payer contracts',
  },
  {
    key: 'reports',
    name: 'reports',
    Icon: reportsResource.icon,
    label: 'Reports',
  },
];
export const resources = [
  <Resource
    key={residentResource.name}
    name={residentResource.name}
    list={residentResource.list}
    show={residentResource.show}
    icon={residentResource.icon}
    options={residentResource.options}
  />,
  <Resource
    key={casesResource.name}
    name={casesResource.name}
    list={casesResource.list}
    icon={casesResource.icon}
    show={casesResource.show}
    options={casesResource.options}
  />,
  <Resource
    key={censusFileResource.name}
    name={censusFileResource.name}
    list={censusFileResource.list}
    icon={censusFileResource.icon}
    show={censusFileResource.show}
    options={censusFileResource.options}
  />,
  <Resource
    key={censusEventResource.name}
    name={censusEventResource.name}
    list={censusEventResource.list}
    icon={censusEventResource.icon}
    show={censusEventResource.show}
    options={censusEventResource.options}
  />,
  <Resource
    key={authsResource.name}
    name={authsResource.name}
    list={authsResource.list}
    icon={authsResource.icon}
    show={authsResource.show}
    options={authsResource.options}
  />,
  <Resource
    key={ancillaryAuthsResource.name}
    name={ancillaryAuthsResource.name}
    list={ancillaryAuthsResource.list}
    icon={ancillaryAuthsResource.icon}
    show={ancillaryAuthsResource.show}
    options={ancillaryAuthsResource.options}
  />,
  <Resource
    key={denialsResource.name}
    name={denialsResource.name}
    list={denialsResource.list}
    icon={denialsResource.icon}
    show={denialsResource.show}
    options={denialsResource.options}
  />,
  <Resource
    key={facilityFaxLogsResource.name}
    name={facilityFaxLogsResource.name}
    list={facilityFaxLogsResource.list}
    icon={facilityFaxLogsResource.icon}
    show={facilityFaxLogsResource.show}
    options={facilityFaxLogsResource.options}
  />,
  <Resource
    key={faxInboxResource.name}
    name={faxInboxResource.name}
    list={faxInboxResource.list}
    icon={faxInboxResource.icon}
    show={faxInboxResource.show}
    options={faxInboxResource.options}
  />,
  <Resource
    key={updatesResource.name}
    name={updatesResource.name}
    list={updatesResource.list}
    icon={updatesResource.icon}
    show={updatesResource.show}
    options={updatesResource.options}
  />,
  <Resource
    key={pharmLogResource.name}
    name={pharmLogResource.name}
    list={pharmLogResource.list}
    icon={pharmLogResource.icon}
    show={pharmLogResource.show}
    options={pharmLogResource.options}
  />,
  <Resource
    key={contractsResource.name}
    name={contractsResource.name}
    list={contractsResource.list}
    icon={contractsResource.icon}
    show={contractsResource.show}
    options={contractsResource.options}
  />,
  <Resource
    key={reportsResource.name}
    name={reportsResource.name}
    list={reportsResource.list}
    icon={reportsResource.icon}
    show={reportsResource.show}
    options={reportsResource.options}
  />,

  <Resource
    key={globalPayersResource.name}
    name={globalPayersResource.name}
    list={globalPayersResource.list}
    show={globalPayersResource.show}
    options={globalPayersResource.options}
  />,

  <Resource
    key={connectionsResource.name}
    name={connectionsResource.name}
    list={connectionsResource.list}
    options={connectionsResource.options}
  />,

  <Resource
    key={servicesResource.name}
    name={servicesResource.name}
    list={servicesResource.list}
    options={servicesResource.options}
  />,
  <Resource
    key={categoriesResource.name}
    name={categoriesResource.name}
    list={categoriesResource.list}
    options={categoriesResource.options}
  />,
  <Resource
    key={taskBuilderResource.name}
    name={taskBuilderResource.name}
    list={taskBuilderResource.list}
    options={taskBuilderResource.options}
  />,
  <Resource key={facilityResource.name} name={facilityResource.name} />,
  <Resource key={tasksResource.name} name={tasksResource.name} />,
  <Resource key='residents' name='residents' />, // for the residents aside
  <Resource key='residents-secondary' name='residents-secondary' />,
  <Resource key='facility-contacts' name='facility-contacts' />,
  <Resource key='facility-groups' name='facility-groups' />,
  <Resource key='communication-groups' name='communication-groups' />,
  <Resource key='case-communications' name='case-communications' />,
  <Resource key='progress-report' name='progress-report' />,
  <Resource key='clinical/manual-diagnoses' name='clinical/manual-diagnoses' />,
  <Resource key='clinical/diagnoses' name='clinical/diagnoses' />,
  <Resource key='clinical/medications' name='clinical/medications' />,
  <Resource key='clinical/pharm-log' name='clinical/pharm-log' />,
  <Resource key='clinical/progress_notes' name='clinical/progress_notes' />,
  <Resource key='global-payer-contracts' name='global-payer-contracts' />,
  <Resource key='documents' name='documents' />,
  <Resource key='case-documents' name='case-documents' />,
  <Resource key='case-emails' name='case-emails' />,
  <Resource key='case-timeline' name='case-timeline' />,
  <Resource key='discharge' name='discharge' />,
  <Resource key='case-updates' name='case-updates' />,
  <Resource key='case-updates-progress' name='case-updates-progress' />,
  <Resource key='case-progress' name='case-progress' />,
  <Resource key='admissions' name='admissions' />,
  <Resource key='case-authorizations' name='case-authorizations' />,
  <Resource
    key='case-ancillary-authorizations'
    name='case-ancillary-authorizations'
  />,
  <Resource key='case-tasks' name='case-tasks' />,
  <Resource key='case-denials' name='case-denials' />,
  <Resource key='case-payers' name='case-payers' />,
  <Resource key='case-therapy' name='case-therapy' />,
  <Resource key='payer-change' name='payer-change' />,
  <Resource key='case-pharmacy' name='case-pharmacy' />,
  <Resource key='bedholds' name='bedholds' />,
  <Resource key='appeals' name='appeals' />,
  <Resource key='denial-types/list' name='denial-types/list' />,
  <Resource key='post-lcd-options/list' name='post-lcd-options/list' />,
  <Resource key='appeal-types/list' name='appeal-types/list' />,
  <Resource key='pharm-log-payers/list' name='pharm-log-payers/list' />,
  <Resource key='resolutions/list' name='resolutions/list' />,
  <Resource key='facilities/list' name='facilities/list' />,
  <Resource key='auth-types/list' name='auth-types/list' />,
  <Resource key='auth-letter-status/list' name='auth-letter-status/list' />,
  <Resource key='auths-status/list' name='auths-status/list' />,
  <Resource key='appeal-statuses/list' name='appeal-statuses/list' />,
  <Resource key='auth-number/list' name='auth-number/list' />,
  <Resource key='cases/list' name='cases/list' />,
  <Resource key='residents/list' name='residents/list' />,
  <Resource key='resident-statuses/list' name='resident-statuses/list' />,
  <Resource key='pharm-log-statuses/list' name='pharm-log-statuses/list' />,
  <Resource key='quality-reviewer/list' name='quality-reviewer/list' />,
  <Resource key='events/list' name='events/list' />,
  <Resource key='payers/list' name='payers/list' />,
  <Resource key='alt-npi/list' name='alt-npi/list' />,
  <Resource key='facility-payers/list' name='facility-payers/list' />,
  <Resource key='cases-payers/list' name='cases-payers/list' />,
  <Resource key='reps/list' name='reps/list' />,
  <Resource key='facility-users/list' name='facility-users/list' />,
  <Resource key='users' name='users' />,
  <Resource key='lob/list' name='lob/list' />,
  <Resource key='gender/list' name='gender/list' />,
  <Resource key='discharge_locations/list' name='discharge_locations/list' />,
  <Resource
    key='communication-outcomes/list'
    name='communication-outcomes/list'
  />,
  <Resource key='communication-types/list' name='communication-types/list' />,
  <Resource key='notes-references/list' name='notes-references/list' />,
  <Resource
    key='external-integrations/list'
    name='external-integrations/list'
  />,
  <Resource key='pcc/facilities' name='pcc/facilities' />,
  <Resource key='pcc/patients' name='pcc/patients' />,
  <Resource key='/communications' name='/communications' />,
  <Resource key='billing-report' name='reports/billing-report' />,
  <Resource key='no-auths-report' name='reports/no-auths-report' />,
  <Resource key='no-updates-report' name='reports/no-updates-report' />,
  <Resource key='census' name='/census' />,
  <Resource key='triple-check' name='reports/triple-check-report' />,
  <Resource key='updates-report' name='reports/updates-report' />,
  <Resource key='payer-rates-report' name='reports/payer-rates-report' />,
  <Resource
    key='changed-planned-discharges-report'
    name='reports/changed-planned-discharges-report'
  />,
  <Resource key='los-discharge-report' name='reports/los-discharge-report' />,
  <Resource
    key='staff-productivity-report'
    name='reports/staff-productivity-report'
  />,
  <Resource key='client-review-report' name='reports/client-review-report' />,
  <Resource key='tasks-due-report' name='reports/tasks-due-report' />,
  <Resource key='updates-due-report' name='reports/updates-due-report' />,
  <Resource key='client-review-summary' name='reports/client-review-summary' />,
  <Resource key='filtered-auth-report' name='reports/filtered-auth-report' />,
  <Resource key='retro-auths-report' name='reports/retro-auths-report' />,
  <Resource key='denial-report' name='reports/denial-report' />,
  <Resource key='therapy-report' name='reports/therapy-report' />,
  <Resource key='updates-schedules' name='updates-schedules' />,
  <Resource key='payers-facility/rates' name='payers-facility/rates' />,
  <Resource key='payers-facility/rate-logs' name='payers-facility/rate-logs' />,
  <Resource key='payers-facility/lob-logs' name='payers-facility/lob-logs' />,
  <Resource
    key='payers-facility/inactive-rates'
    name='payers-facility/inactive-rates'
  />,
  <Resource
    key='payers-facility/rates-services'
    name='payers-facility/rates-services'
  />,
  <Resource key='services/list' name='services/list' />,
  <Resource key='task-types/list' name='task-types/list' />,
  <Resource key='task-documents/list' name='task-documents/list' />,
  <Resource key='related-options/list' name='related-options/list' />,
  <Resource key='payers-facility/outliers' name='payers-facility/outliers' />,
  <Resource
    key='payers-facility/integration-details'
    name='payers-facility/integration-details'
  />,
  <Resource
    key='payers-facility/payer-reps'
    name='payers-facility/payer-reps'
  />,
  <Resource key='contact-roles/list' name='contact-roles/list' />,
  <Resource key='facility_contact_id' name='facility_contact_id' />,
  <Resource key='case-eligibility' name='case-eligibility' />,
  <Resource key='case-history' name='case-history' />,
  <Resource key='mltc-payer' name='mltc-payer' />,
  <Resource
    key='insufficient-criteria-reasons/list'
    name='insufficient-criteria-reasons/list'
  />,
  <Resource key='level-one-reasons/list' name='level-one-reasons/list' />,
  <Resource key='user-coverage' name='user-coverage' />,
  <Resource key='medications/list' name='medications/list' />,
  <Resource key='global-rev-codes' name='global-rev-codes' />,
  <Resource key='nomnc-appeal-address' name='nomnc-appeal-address' />,
  <Resource key='merge-residents' name='merge-residents' />,
  <Resource key='auth-diagnoses/list' name='auth-diagnoses/list' />,
  <Resource key='service-diagnoses/list' name='service-diagnoses/list' />,
  <Resource
    key='transition-facility/open-auths'
    name='transition-facility/open-auths'
  />,
];
