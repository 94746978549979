import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  // EmailField,
  // Filter,
  // SelectInput,
  FunctionField,
  // NumberField,
  // DateField,
  useRefresh,
} from 'react-admin';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { Datagrid } from '../../components/common';
import { NewEvent } from '../../components/common/modals';
import { AddIcon, EditIcon, CloseIcon, CustomButton } from '../../design';
import { useGetUserRoles } from '../../hooks';
import { limitStringSize } from '../../utils';
import { useStyles } from './facility.styles';

const ListActions = ({ permissions, record }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='group'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='communicationGroup'
          refresh={refresh}
          record={record}
        />
      )}
    </Fragment>
  );
};

export const CommunicationGroups = ({ id, isAdmin, isViewAdmin, ...props }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { is_admin, is_supervisor, is_admin_assistant } = useGetUserRoles();
  const [eventOpen, setEventModal] = useState(false);
  const [communicationRecord, setCommunicationRecord] = useState();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');
  const maxNotesLength = 85;
  const { record = {} } = props;
  useEffect(() => {
    // see comment on case_communication
    if (communicationRecord) {
      setEventModal(true);
    }
  }, [communicationRecord]);
  useEffect(() => {
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);
  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    setSelectedNote('');
  };

  const handleModalClose = () => {
    setEventModal(false);
    setCommunicationRecord(undefined);
  };
  const controllerProps = useListController({
    ...props,
    resource: 'communication-groups',
    filter: { facility_log_id: record?.facility_log_id },
  });
  return (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        actions={
          is_admin || is_supervisor || is_admin_assistant ? (
            <ListActions record={record} />
          ) : null
        }
      >
        <Datagrid>
          <TextField
            source='name'
            label='Name'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='note'
            label='Notes'
            cellClassName={classes.notesCell}
            render={record => {
              const { note } = record;
              const truncated = limitStringSize(note, maxNotesLength, true);
              return note && note.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setSelectedNote(record.note)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <FunctionField
            source='facility_contacts'
            label='Members'
            sortable={false}
            headerClassName={classes.listItemLabel}
            render={record => {
              const { facility_contacts: _facility_contacts } = record;
              // could be null...
              const members = _facility_contacts ? _facility_contacts : null;
              return Array.isArray(members)
                ? members.map((m, index) => (
                    <span key={m.id}>
                      {m.full_name} {index !== members.length - 1 ? ', ' : null}
                    </span>
                  ))
                : null;
            }}
          />

          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return is_admin || is_supervisor || is_admin_assistant ? (
                <CustomButton
                  Icon={EditIcon}
                  color='#1061A0'
                  backgroundColor='#EFF4FB'
                  label='Edit'
                  type='button'
                  variant='text'
                  size='small'
                  onClick={() => setCommunicationRecord(record)}
                />
              ) : null;
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={{
            ...communicationRecord,
            facility_log_id: record.facility_log_id,
          }}
          form='communicationGroup'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
        />
      )}
      {showNotesModal && (
        <NotesModal
          open={showNotesModal}
          handleClose={handleNotesModalClose}
          note={selectedNote}
        />
      )}
    </div>
  );
};

const NotesModal = ({ note, open, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <DialogContent>
        <h1 className={classes.title}>Notes</h1>
        <DialogContentText>{note}</DialogContentText>
        <DialogActions>
          <CustomButton
            Icon={CloseIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Close'
            type='button'
            variant='text'
            size='small'
            onClick={handleClose}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
