import React, { useState, useEffect, Fragment } from 'react';

import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  // SelectComponent,
  handleNumbers,
  getDateInputValue,
  minReasonLength,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const CommunicationGroup = ({
  record = {},
  isEdit,
  payerFacilityId,
  handleClose,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    payer_facility_id: payerFacilityId,
    facility_contact_id: [],
  });
  useEffect(() => {
    if (isEdit && record) {
      const { id, facility_contacts, ...rest } = record;
      const facility_contact_id = Array.isArray(facility_contacts)
        ? facility_contacts.map(m => m.id)
        : [];
      setFormData(f => ({
        ...f,
        ...rest,
        facility_contact_id,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('communication-groups', {
          id: record.id,
          data: { ...value, facility_log_id: record.facility_log_id },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('communication-groups', {
          data: { ...value, facility_log_id: record.facility_log_id },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <FormTextField
                  name='name'
                  label='Group Name'
                  customOnChange={customOnChange}
                  fullWidth
                  className={classes.carvOuts}
                  validate={required()}
                  required
                />
                <FormTextField
                  name='note'
                  label='Notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  className={classes.carvOuts}
                  validate={minReasonLength()}
                />
                <ReferenceInput
                  reference='facility-contacts/list'
                  customOnChange={customOnChange}
                  name='facility_contact_id'
                  label='Add members'
                  validate={required()}
                  required
                  multiple
                  renderWith='chip'
                  fullWidth
                  className={classes.carvOuts}
                  options={{
                    filter: { facility_log_id: record.facility_log_id },
                  }}
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
