import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';
import { Form } from 'react-final-form';
import {
  ExportButton,
  useNotify,
  useRefresh,
  useDataProvider,
  cacheDataProviderProxy,
  List,
  downloadCSV,
  useListController,
  Pagination,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  ChipField,
  TopToolbar,
} from 'react-admin';
import { DialogActions, DialogContent, Button, Chip } from '@material-ui/core';
import { FormTextField, CheckboxInput } from '.';
import { DateField } from '../../../common';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { getChip } from '../../../../utils';

export const TransitionFacilityForm = ({ record, changeForm, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const [transitionDate, setTransitionDate] = useState();
  const [copyContacts, setCopyContacts] = useState(false);
  const onSubmit = value => {
    return dataProvider
      .update('transition-facility', {
        id: record.id,
        data: {
          transition_date: transitionDate,
          copy_contacts: copyContacts,
        },
        previousData: { ...record },
      })
      .then(({ data }) => {
        notify('form.updated');
        changeForm('facility', {
          record: data,
          isEdit: true,
        });

        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={e => setTransitionDate(e.target.value)}
                    name='transition_date'
                    type='date'
                    label='Transition date'
                    required
                  />
                  <CheckboxInput
                    name='copy_contacts'
                    customOnChange={e => setCopyContacts(e.target.checked)}
                    label=' Copy facility contacts'
                    checked={copyContacts}
                  />
                  <span style={{ width: 400 }} />
                </div>
                {transitionDate && (
                  <OpenAuthsList
                    record={record}
                    transitionDate={transitionDate}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SaveButton
                  className={classes.saveButton}
                  type='submit'
                  label='Save and edit facility'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
const OpenAuthsList = ({ record, transitionDate, ...props }) => {
  const classes = useStyles();
  const controllerProps = useListController({
    ...props,
    resource: 'transition-facility/open-auths',
    basePath: '/transition-facility/open-auths',
    filter: { id: record.id, transition_date: transitionDate },
  });

  const ListActions = ({ currentSort, resource, total }) => {
    return (
      <TopToolbar className={classes.listActionWrapper}>
        <ExportButton
          resource={resource}
          sort={currentSort}
          exporter={exporter}
          className={classes.exportButton}
        />
      </TopToolbar>
    );
  };
  return (
    <div style={{ paddingTop: 20 }}>
      <span className={classes.warnColor}>
        These auths will be closed on the transition date:
      </span>
      <List
        style={{ marginTop: -40 }}
        empty={false}
        {...controllerProps}
        actions={<ListActions />}
        bulkActionButtons={false}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <TextField source='insurance_auth_number' label='Auth #' />
          <FunctionField
            source='authorization_type'
            label='Rate type'
            render={record => {
              const { authorization_type, sub_level } = record;
              if (!authorization_type) {
                return null;
              }
              return sub_level
                ? `${authorization_type.type} - ${sub_level}`
                : authorization_type.type;
            }}
          />
          <FunctionField
            source='case_name'
            label='Case name'
            render={record => {
              const { v_case } = record;
              if (v_case) {
                return `${v_case.last_name} ${v_case.first_name} - ${v_case.admit_date}`;
              }
            }}
          />
          <FunctionField
            label='Status'
            render={record => {
              const { is_retro } = record;
              return (
                <ReferenceField
                  source='authorization_status_id'
                  reference='auths-status/list'
                  link={false}
                >
                  <FunctionField
                    source='name'
                    render={record => {
                      return (
                        <div style={{ width: 90 }}>
                          <ChipField
                            record={record}
                            source='name'
                            className={clsx(
                              classes.chip,
                              classes[getChip(record.name)],
                            )}
                          />
                          {is_retro ? (
                            <Chip
                              size='small'
                              label='Retro Auth'
                              className={clsx(
                                classes.chip,
                                classes[getChip('Retro')],
                              )}
                            />
                          ) : null}
                        </div>
                      );
                    }}
                  />
                </ReferenceField>
              );
            }}
          />
          <DateField source='start_date' label='Start date' />
          <FunctionField
            source='end_date'
            label='End date'
            render={record => (
              <DateField
                record={record}
                source={record.actual_end_date ? 'actual_end_date' : 'end_date'}
                label='End date'
              />
            )}
          />
          <TextField
            source='cases_payer.payers_facility.Payers.name'
            label='Payer'
          />
          <FunctionField
            label='Payer priority'
            render={record => {
              return record && record.is_primary !== null
                ? record.is_primary
                  ? 'Primary'
                  : 'Secondary'
                : null;
            }}
          />
        </Datagrid>
      </List>
    </div>
  );
};
const exporter = rows => {
  const fieldsForExport = rows.map(field => {
    const {
      insurance_auth_number,
      authorization_type,
      authorization_status,
      sub_level,
      v_case,
      start_date,
      end_date,
      actual_end_date,
      is_primary,
      cases_payer,
    } = field;
    return {
      insurance_auth_number,
      authorization_type: sub_level
        ? `${authorization_type.type} - ${sub_level}`
        : authorization_type.type,
      authorization_status: authorization_status.status,
      case_name: `${v_case.last_name} ${v_case.first_name} - ${v_case.admit_date}`,
      start_date,
      end_date: actual_end_date || end_date,
      payer_name: cases_payer?.payers_facility?.Payers?.name,
      payer_priority: is_primary ? 'Primary' : 'Secondary',
    };
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'insurance_auth_number',
        'authorization_type',
        'case_name',
        'authorization_status',
        'start_date',
        'end_date',
        'payer_name',
        'payer_priority',
      ],
      rename: [
        'Auth #',
        'Rate type',
        'Case name',
        'Auth status',
        'Start date',
        'End date',
        'Payer',
        'Payer priority',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `open-auths-${format(new Date(), 'MM/dd/yyyy')}`);
    },
  );
};
